import { FormikHelpers, FormikProps } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ComponentProps, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';

// Display components
import { hasValidDateOfBirth } from 'display-components/membership/forms/validations';

// Components
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipAutoRefillSection from 'components/membership-auto-refill-section/membership-auto-refill-section.component';
import MembershipRegistrationContactForm from 'components/membership-registration-contact-form/membership-registration-contact-form.component';
import MembershipRegistrationInsuranceIdValidate from 'components/membership-registration-insurence-id-validate/membership-registration-insurance-id-validate.component';
import MembershipRegistrationPersonalInformationForm from 'components/membership-registration-personal-information-form/membership-registration-personal-information-form.component';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// Reducers
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { setMembershipFormValues } from 'state/membership-registration/membership-registration.reducer';
// State
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine,
    membershipRegistrationRegisterRoutine,
    membershipRegistrationValidateInsuranceIdRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationAutoRefillSelector,
    membershipRegistrationFormValuesSelector,
    membershipRegistrationIsCaliforniaUserSelector,
    membershipRegistrationValidateInsuranceSelector
} from 'state/membership-registration/membership-registration.selectors';
import { PatientRegistrationPayload } from 'state/membership-registration/membership-registration.services';

// Schemas
import { CREATE_BIRDI_ACCOUNT_PERSONAL_INFO_SCHEMA } from 'schema/create-birdi-account-personal-info.schema';

// Types
import {
    CreateBirdiAccountContacts,
    CreateBirdiAccountPersonalInformation,
    MembershipPersonalDetailsFormValues,
    MembershipRegistrationFormPersonalInfoProps
} from 'types/membership';

// Utils
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

// Styles
import './index.style.scss';

type validInsuranceType = 'hide' | 'valid' | 'invalid' | 'registered' | 'error';

const Insured = ({ location, data }: { location: any; data: GatsbyTypes.InsuredDataQuery }): ReactElement => {
    const { t } = useTranslation();
    const formRef = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const formRefPhone = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const [insuranceId, setInsuranceId] = useState<string>();
    const [registrationDataValues, setRegistrationValues] = useState<PatientRegistrationPayload>();
    const [showValidInsuranceId, setShowValidInsuranceId] = useState<validInsuranceType>('hide');
    const [isValidateInsurance, setIsValidateInsurance] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isValidateJoin, setIsValidateJoin] = useState<boolean>(false);
    const [isAutoRefill, setIsAutoRefill] = useState<boolean>(false);
    const [isValidateSubmitting, setIsValidateSubmitting] = useState(false);

    const dispatch = useDispatch();

    const membershipFormValues = useSelector(membershipRegistrationFormValuesSelector);
    const resultValidateInsurance = useSelector(membershipRegistrationValidateInsuranceSelector);
    const isCaliforniaUser = useSelector(membershipRegistrationIsCaliforniaUserSelector);
    const isAutoRefillEligible = useSelector(membershipRegistrationAutoRefillSelector);

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    useEffect(() => {
        dispatch(membershipRegistrationGetGendersRoutine.trigger());
        dispatch(membershipRegistrationGetRacesRoutine.trigger());
        dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
    }, [dispatch]);

    useEffect(() => {
        if (resultValidateInsurance?.validate) {
            if (resultValidateInsurance.validate === 'valid') {
                // valid form state
                setShowValidInsuranceId(resultValidateInsurance.validate);
                setIsValidateInsurance(true);
                setIsValidateJoin(true);
            } else {
                // invalid Personal Information form state
                setShowValidInsuranceId(resultValidateInsurance.validate as validInsuranceType);
                setIsValidateInsurance(false);
                setIsValidateJoin(false);
            }
        }
    }, [resultValidateInsurance, isSubmitting]);

    const onSubmitInsuranceId = async (values: Partial<CreateBirdiAccountPersonalInformation>) => {
        if (formRef.current?.isValid && !formRef.current?.isDirty) {
            setIsValidateSubmitting(true);
        }

        // validate insurance ID
        validatePersonalInformation(values);
    };

    const validatePersonalInformation = (formValues: Partial<CreateBirdiAccountPersonalInformation>) => {
        if (!insuranceId) {
            setShowValidInsuranceId('hide');
            setIsValidateJoin(false);
            return;
        }

        if (showValidInsuranceId === 'valid') {
            setIsValidateSubmitting(false);
            return;
        }

        dispatch(
            membershipRegistrationValidateInsuranceIdRoutine.trigger({
                formValues,
                insuranceId,
                onSuccess: (data: PatientRegistrationPayload) => {
                    if (data) {
                        setRegistrationValues(data);
                        // set valid state
                        setShowValidInsuranceId('valid');
                        setIsValidateInsurance(true);
                        setIsValidateJoin(true);
                    }

                    setIsValidateSubmitting(false);
                },
                onFailure: (data: PatientRegistrationPayload) => {
                    setIsValidateSubmitting(false);
                }
            })
        );
    };

    const handleJoinSubmit = () => {
        if (formRefPhone.current) {
            formRefPhone.current.validateForm();
            formRefPhone.current.submitForm();
        }
    };

    /**
     * handleFormChange
     */
    type FormChangeEvent = ComponentProps<'form'>['onChange'];

    const handleFormChange = useCallback(
        (e: FormChangeEvent) => {
            const ignoredKeys = ['gender', 'race', 'ethnicity', 'careProvider'];
            // check if form is valid
            if (isValidateInsurance && !ignoredKeys.includes(e?.target.name as any)) {
                // reset validate flag
                setIsValidateInsurance(false);

                // Invalidate the form state & reset the insurance ID field
                setShowValidInsuranceId('invalid');
                setIsValidateJoin(false);
                setInsuranceId(undefined);
            }
        },
        [isValidateInsurance]
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    const handleErrorModal = (message: string = t('registration.errors.modals.default.body')) => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={message}
                        note={t('registration.errors.modals.default.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ProfileSetupError',
                        label: t('registration.errors.modals.default.button'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('registration.errors.modals.default.title')} icon="alert" />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    };

    const onJoin = (formValues: Partial<CreateBirdiAccountContacts>, helpers: FormikHelpers<Partial<any>>) => {
        const personalValues = formRef.current?.values as MembershipPersonalDetailsFormValues;
        const data = {
            ...registrationDataValues,
            FirstName: personalValues?.firstName,
            LastName: personalValues?.lastName,
            PrimaryCareProvider: personalValues?.careProvider,
            BirthDate: `${personalValues?.dobYear}-${personalValues?.dobMonth}-${personalValues?.dobDay}`,
            AutoRefill: isAutoRefill,
            RaceId: personalValues?.race,
            GenderCode: personalValues?.gender,
            EthnicityId: personalValues.ethnicity,
            Email: formValues?.username,
            PhoneNumber: formValues?.phoneNumber,
            Password: formValues?.password,
            PasswordConfirm: formValues?.passwordConfirm
        };

        setIsSubmitting(true);

        // Setting auto refill toggle flag in local storage
        // to don't ask the user again to join auto refill when logs in
        storageHelper.local.setAutoRefillToggleFlag();

        dispatch(setMembershipFormValues({ ...membershipFormValues, ...personalValues, ...formValues }));

        dispatch(
            membershipRegistrationRegisterRoutine.trigger({
                formValues: data,
                formHelpers: helpers,
                onFailure: (error: any) => {
                    const errorMessage = error && error.SystemMessage ? error.SystemMessage.toLowerCase() : '';
                    const modalMessage = errorMessage.includes('username is invalid')
                        ? t('registration.errors.modals.emailAlreadyOnFile.body')
                        : undefined;

                    setIsSubmitting(false);
                    handleErrorModal(modalMessage);
                },
                onSuccess: () => {
                    setIsSubmitting(false);
                    setIsValidateInsurance(false);
                    setInsuranceId(undefined);
                    setShowValidInsuranceId('hide');
                    navigate('/confirm-email?flow=mail-order');
                }
            })
        );
    };

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.getStarted.eyebrowText') }}
            anonymousOnly={true}
            footerLinks={workflowFooterLinks}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'membership-registration-header'}
                eyebrowText={t('membership.getStarted.eyebrowText')}
                title={t('membership.getStarted.profileTitle')}
            >
                <div className="sign-in-link">
                    <Link
                        to="/sign-in"
                        label={t('membership.getStarted.signIn')}
                        dataGALocation="membership"
                        className="sm-full text-center font-weight-bold"
                        variant="underline-blue"
                        external={false}
                    />
                </div>
                <MembershipRegistrationPersonalInformationForm
                    state={location.state}
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        dateOfBirth: '',
                        dobMonth: '',
                        dobDay: '',
                        dobYear: '',
                        gender: '',
                        race: '',
                        ethnicity: '',
                        careProvider: '',
                        insuranceId: undefined
                    }}
                    allowMinorsRegistration={true}
                    validationSchema={CREATE_BIRDI_ACCOUNT_PERSONAL_INFO_SCHEMA}
                    onCancel={noop}
                    onSubmit={onSubmitInsuranceId}
                    onChange={handleFormChange}
                    onValidate={(values) => {
                        const errors = hasValidDateOfBirth(values);

                        // updated insurance ID
                        if (values?.insuranceId !== insuranceId) {
                            setInsuranceId(values.insuranceId ?? undefined);
                        }

                        // validate insurance id
                        if (values?.insuranceId === null) {
                            setIsValidateInsurance(false);
                            setShowValidInsuranceId('invalid');

                            errors.insuranceId = 'invalid';
                        }

                        return errors;
                    }}
                    ref={formRef}
                >
                    <MembershipRegistrationInsuranceIdValidate
                        state={location.state}
                        validateStatus={showValidInsuranceId}
                        isValidateJoinStatus={isValidateJoin}
                        insuranceId={insuranceId}
                        isBusy={isValidateSubmitting}
                    />
                </MembershipRegistrationPersonalInformationForm>

                {isValidateInsurance && !isValidateSubmitting ? (
                    <>
                        <div className="membership-registration-mt-5">
                            <MembershipRegistrationContactForm
                                initialValues={{
                                    phoneNumber: '',
                                    username: '',
                                    password: '',
                                    passwordConfirm: ''
                                }}
                                onCancel={noop}
                                onSubmit={onJoin}
                                ref={formRefPhone}
                            />
                        </div>
                        {!isCaliforniaUser && isAutoRefillEligible && (
                            <div className="membership-registration-mt-5">
                                <MembershipAutoRefillSection
                                    handleChange={(isChecked) => setIsAutoRefill(isChecked)}
                                    handleLearnMoreModal={handleAutoRefillModal}
                                />
                            </div>
                        )}
                        <div className="membership-registration-disclaimer membership-registration-mt-5">
                            <div>
                                <div className="membership-registration-button-align">
                                    <Button
                                        async
                                        label={t('membership.getStarted.join')}
                                        className="md-full btn-bold"
                                        type="submit"
                                        variant="primary"
                                        // eslint-disable-next-line no-console
                                        onClick={() => handleJoinSubmit()}
                                        disabled={isSubmitting}
                                        isBusy={isSubmitting}
                                        dataGAFormName="Insured"
                                        dataGALocation="Insured"
                                    />
                                </div>
                                <div className="membership-select-plan__sections__agreement">
                                    <p>{t('membership.selectPlan.userAgreementHeader2')}</p>
                                    <p>{t('membership.selectPlan.userAgreement3')}</p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="mail-order" />
        </WorkflowLayout>
    );
};
export default Insured;

export const query = graphql`
    query InsuredData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "mail_order_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
